jQuery(function ($) {
  $(document).ready(function () {

    var brandSet = getCookie("nhci-brand");

    if (brandSet === "") {
      //check if brand is set
      $(".modal").fadeIn();
      if ($(".modal a")) {
        $(".modal a").focus();
      } else {
        $(".modal .close-btn").focus();
      }

      var currentHeight = 1;
      $('.modal--inner--columns--column--content').each(function(){
        var h = $(this).height();
        if(h > currentHeight) {
          currentHeight = h;
        }
      })
      $('.modal--inner--columns--column--content').height(currentHeight);
    }

    $(".modal .close-btn").on("click", function (e) {
      e.preventDefault();
      setCookie("nhci-brand", "consumer", 1);
      $(".modal").fadeOut();
    });

    $(".modal--cta").on("click", function (e) {
      e.preventDefault();
      var brand = $(this).attr("data-brand");
      var link = $(this).attr("href");
      var target = $(this).attr("target");
      setCookie("nhci-brand", brand, 30);
      window.open(link, target);
    });

    /** Modal focus trap */
    // list of all elements that can get focus
    const focusableElements =
      'button, [href], input:not([type=hidden]), input[type=radio], select, textarea, [tabindex]:not([tabindex="-1"])';

    // reusable function
    const modalFocusTrap = function (modal) {
      // cache some elements
      const firstFocusableElement =
        modal.querySelectorAll(focusableElements)[0]; // get first element to be focused inside modal
      // array of all focusable
      const focusableContent = modal.querySelectorAll(focusableElements);
      const lastFocusableElement =
        focusableContent[focusableContent.length - 1]; // get last element to be focused inside modal

      document.addEventListener("keydown", function (e) {
        let isTabPressed = e.key === "Tab" || e.keyCode === 9;
        // exit asap if not relevant
        if (!isTabPressed) {
          return;
        }

        if (e.shiftKey) {
          // if shift key pressed for shift + tab combination
          if (document.activeElement === firstFocusableElement) {
            lastFocusableElement.focus(); // add focus for the last focusable element
            e.preventDefault();
          }
        } else {
          // if tab key is pressed
          if (document.activeElement === lastFocusableElement) {
            // if focused has reached to last focusable element then focus first focusable element after pressing tab
            firstFocusableElement.focus(); // add focus for the first focusable element
            e.preventDefault();
          }
        }
      });
    };

    // apply
    if ($(".modal").length) {
      modalFocusTrap(document.querySelector(".modal"));
    }
  });
});
